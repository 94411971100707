import React, { Fragment, useContext, useEffect } from 'react'
import FadeIn from '../utils/FadeIn';
import CoursesComponent from './CoursesComponent';
import ConfigContext from '../../ConfigContext';

const SkillItem = ({ skill }) => {
    return (
        <li className="bg-red-100 rounded-full px-4 py-2 m-2 text-center text-sm font-semibold text-red-700">
            {skill}
        </li>
    );
};

const SkillsList = ({ skills }) => {
    return (
        <ul className="flex flex-wrap justify-center items-center p-5">
            {skills.map((skill, index) => (
                <SkillItem key={index} skill={skill} />
            ))}
        </ul>
    );
};


const Skills = () => {
    const config = useContext(ConfigContext);
    const skills_page = config.skills_page ?? [];
    const stats_page_forWhom = config.stats_page_forWhom ?? [];
    const stats_page_courses = config.stats_page_courses ?? [];
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Fragment>
            <section className="flex-row sm:flex-col m-8 md:my-24 my-8  text-black body-font ">

                <div>
                    <h4 className="text-5xl text-black font-bold tracking-normal  sm:text-6xl">
                        Courses on RACE
                    </h4>
                    <p className='m-4 text-xl'>Learn programming languages and concepts to prepare for a career in hardware or software development</p>
                    <FadeIn>
                        <SkillsList skills={skills_page} />
                    </FadeIn>
                </div>
                <FadeIn>
                    <CoursesComponent courses={stats_page_courses} forWhom={stats_page_forWhom} />
                </FadeIn>


            </section>
        </Fragment>
    )
}

export default Skills