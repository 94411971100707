import { ClockIcon, CurrencyRupeeIcon, EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'

const Footer = () => {
    return (
        <Fragment>


            <footer id='contact-us' className="bg-white dark:bg-black text-white ">
                {/* This Social Media Icon Components */}
                <div

                    className="flex   items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
                    <div className="me-12 hidden lg:block">
                        <span>Get connected with us on social networks:</span>
                    </div>
                    {/* <!-- Social network icons container --> */}
                    <div className="flex justify-center  gap-4">
                        <a href="https://www.facebook.com/lavishsirfanclub/" className="me-6 [&>svg]:h-6 [&>svg]:w-6">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 320 512">
                                <path
                                    d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                            </svg>
                        </a>
                        <a href="https://twitter.com/RaceOfAdvance" className="me-6 [&>svg]:h-6 [&>svg]:w-6 ">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 512 512">
                                <path
                                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/raceofadvancecomputing/" className="me-6 [&>svg]:h-6 [&>svg]:w-6">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 448 512">
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/in/lavish-arora-1668ba65/" className="me-6 [&>svg]:h-6 [&>svg]:w-6">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 448 512">
                                <path
                                    d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="mx-auto w-full  p-4 py-6 lg:py-8">
                    <div className="flex flex-col md:flex-row justify-end">
                        <div className=" m-4 flex flex-col gap-4  ">
                            <a href="https://raceitsolution.co.in/" className="flex flex-col  mb-8 md:flex-row gap-4 items-center">
                                <img src="/images/logo_raceitsolutions.png" className="h-24" alt="FlowBite Logo" />
                                <div className="self-center text-2xl font-bold whitespace-nowrap dark:text-white">RACE<span className='text-red-500'> IT </span>SOLUTION</div>
                            </a>
                            {/* Location */}
                            <div className='flex gap-2 align-middle items-center'>
                                <MapPinIcon className="h-8 w-8 text-white font-bold" style={{ width: '2rem !important', height: '2rem !important' }} />
                                <div className="text-gray-500 dark:text-gray-400 flex">
                                    <span className='text-white font-semibold sr-only'>Address : </span>
                                    <span>Office 15, Vatika Kunj, Bhondsi, Gurugram, Maruti Kunj, Haryana, 122102</span>
                                </div>
                            </div>

                            {/* Contact Us */}
                            <div className='flex  gap-2  align-middle items-center'>
                                <PhoneIcon className="h-8 w-8  text-white font-bold" />
                                <div className="text-gray-500 dark:text-gray-400 flex ">
                                    <span className='text-white font-semibold  sr-only'>Call Us : </span>
                                    <span>+91-8800-701-946 , +91-9999057839</span>
                                </div>
                            </div>

                            {/* Email Address */}
                            <div className='flex  gap-2  align-middle items-center'>
                                <EnvelopeIcon className="h-8 w-8   text-white font-bold " style={{ width: '32px !important', height: '32px' }} />
                                <div className="text-gray-500 dark:text-gray-400 flex">
                                    <span className='text-white font-semibold sr-only'>Email</span>
                                    <span className='normal-case'> raceofeducation@gmail.com, lavisharora133@gmail.com, race@raceitsolution.co.in</span>
                                </div>
                            </div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14044.268942624152!2d77.08916!3d28.3568168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23e84406aaab%3A0x4c9bca4727b52e89!2sRACE%20OF%20ADVANCE%20COMPUTING%20EDUCATION%20PVT.%20LTD!5e0!3m2!1sen!2sin!4v1713478971374!5m2!1sen!2sin" className='w-full h-56 max-h-max' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                        <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700 md:sr-only" />


                        {/* Secound Part */}
                        <div className="flex flex-col gap-8 m-4 w-full md:w-1/2">
                            <div>
                                <h2 className="flex flex-row gap-2 mb-6 text-2xl font-bold  text-gray-900 uppercase dark:text-white">
                                    <ClockIcon className="h-8 w-8 text-white font-bold " />
                                    <span className='text-white font-bold underline underline-offset-8 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400'>Working Hours</span>

                                </h2>
                                <p>
                                    <div className='mb-8'>Our services are available during the following hours</div>
                                    <div>
                                        <div>
                                            <div className='break-words'><span className="font-semibold">India Standard Time (IST) : </span><span>6:00 AM to 11:00 PM</span></div>
                                            <div className='break-words'><span className="font-semibold">International Clients : </span><span>8:00 PM to 6:00 AM IST</span></div>
                                        </div>
                                    </div>
                                </p>


                            </div>


                            <div id='payment'>
                                <h2 className="flex flex-row gap-2 mb-6 text-2xl font-bold  text-gray-900 uppercase dark:text-white">
                                    <CurrencyRupeeIcon className="h-8 w-8 text-white font-bold" />
                                    <span className='text-white font-bold underline underline-offset-8 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400'>Payments</span>
                                </h2>
                                <div>
                                    <div>
                                        <div>To complete your payment, you can choose from the following options:</div>

                                        <div className='mb-2'>
                                            <span className="font-semibold">Option 1 : </span>
                                            <span>Scan Our QR Code</span>
                                            <img className='mt-2 w-32 h-32' src="/images/paymentqr.webp" alt="" />
                                        </div>
                                        <div className='mb-2'>
                                            <span className="font-semibold">Option 2 : </span>
                                            <span>Use our bank account details</span>
                                            <div>
                                                <div className='my-4'>
                                                    <div className="font-semibold">Bank Details : </div>
                                                    <div><span className='font-semibold'>Account Number :</span> 9192 2010 0128 53 </div>
                                                    <div><span className='font-semibold'>IFSC Code :</span> CNRB0005693</div>
                                                    <div><span className='font-semibold'>Account Holder's Name :</span>  LAVISH ARORA</div>
                                                    <div><span className='font-semibold'>Mobile Number :</span> +91 8800 701 946</div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <span className="font-semibold italic">Your support is greatly appreciated!</span>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

                    <p className="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 sm:mb-0">
                        &copy; 2024 <a href="https://raceitsolution.co.in/" className="hover:underline" target="_blank">RaceItsolution</a>. All rights reserved. Build By <a href="https://itsjinendrajain.com/" className="hover:underline" target="_blank">Jinendra Jain</a>
                    </p>
                </div>

            </footer>

        </Fragment>
    )
}

export default Footer