import React, { Fragment, useContext } from 'react'
import FadeIn from '../utils/FadeIn'
import ConfigContext from '../../ConfigContext'




const StatsSingle = ({ img, heading, subtitle = "" }) => {
    return (
        <Fragment>


            <div className="flex flex-row   justify-center  align-middle  items-center gap-2 p-4 sx:w-1/2 lg:w-1/4 w-full">
                <div>
                    <img className='w-10' src={img} alt="" />
                </div>
                <div>
                    <h2 className="title-font font-bold  text-5xl text-black">{heading}</h2>
                    <p className="leading-relaxed font-bold text-black">{subtitle}</p>
                </div>
            </div>

        </Fragment>
    )
}

const Stats = () => {
    const config = useContext(ConfigContext);
    const StatsList = config.HomeStatsList ?? [];
    return (
        <Fragment>
            <hr className="mx-auto my-4 w-[50%] md:w-[50%]   text-center h-1 bg-gray-100 rounded border-0  dark:bg-gray-700" />
            <section className="flex-row sm:flex-col m-3 md:my-24 my-8  text-black body-font ">
                <FadeIn>
                    <div>
                        <h4 className="text-4xl text-black font-bold tracking-tight text-center sm:text-6xl">
                            We're increasing this data every year

                        </h4>
                        <p className="mt-6 text-base   leading-relaxed text-black text-center">With our super powers we have reached this</p>


                    </div>
                    <div className="container px-5 py-20 mx-auto">
                        <div className="flex flex-wrap items-center justify-center -m-4 text-center">
                            {StatsList.map((item, index) => (
                                <StatsSingle key={index} {...item} />
                            ))}
                            

                        </div>
                    </div>
                </FadeIn>
            </section>
            {/* <hr className="mx-auto my-4 w-[50%] md:w-[50%]   text-center h-1 bg-gray-100 rounded border-0  dark:bg-gray-700" /> */}
        </Fragment>
    )
}

export default Stats