// src/ConfigProvider.js
import React, { useState, useEffect } from 'react';
import ConfigContext from './ConfigContext';

const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({

        "home_page_about_list": [
            {
                "name": "Programming & Web Development", "icon": "/images/about/1.webp", "description": "Master languages like Java, Python, & C++. Dive into HTML, CSS, & JavaScript.",
                "gradient": "from-blue-500 to-teal-500"
            },
            {
                "name": "Cloud Computing", "icon": "/images/about/2.webp", "description": "Navigate the latest in cloud technologies with AWS, Azure, & Google Cloud platforms.",
                "gradient": "from-indigo-500 to-purple-500"
            },
            {
                "name": "Network & Cybersecurity", "icon": "/images/about/3.webp", "description": "Understand network administration using TCP/IP, DNS, & DHCP. Protect systems with cybersecurity components.",
                "gradient": "from-lime-500 to-green-500"
            },
            {
                "name": "Data Science & Machine Learning", "icon": "/images/about/4.webp", "description": "Analyze data with Excel, R, & Tableau. Build solutions using TensorFlow & scikit-learn.",
                "gradient": "from-orange-500 to-amber-600"
            },
            {
                "name": "Mobile & Agile Methodologies", "icon": "/images/about/5.webp", "description": "Develop mobile applications for iOS & Android. Implement agile practices with Scrum & Kanban.",
                "gradient": "from-yellow-500 to-amber-500"
            },
            {
                "name": "DevOps & UI/UX Design", "icon": "/images/about/6.webp", "description": "Embrace modern DevOps principles with continuous integration & Docker. Design user experiences using Adobe XD & Sketch.",
                "gradient": "from-cyan-500 to-blue-500"
            },
            {
                "name": "Project Management & Quality Assurance", "icon": "/images/about/7.webp", "description": "Master project management strategies including PMP & Agile. Ensure software quality with test automation & bug tracking.",
                "gradient": "from-pink-500 to-red-600"
            },
            {
                "name": "Advanced Technologies", "icon": "/images/about/8.webp", "description": "Explore big data technologies with Hadoop & Spark. Manage systems using Linux administration & IT support.",
                "gradient": "from-teal-500 to-cyan-600"
            },
            {
                "name": "Innovative Learning", "icon": "/images/about/9-1.webp", "description": "Step into the future with robotics, automation, & technical writing.",
                "gradient": "from-purple-500 to-violet-600"
            }
        ],
        "HomeLearningoptionList": [
            {
                "image": "/images/leraningOption/FlexibleDates.png",
                "alt": "Flexible Dates"
            },
            {
                "image": "/images/leraningOption/LiveOnline.png",
                "alt": "Live online"
            },
            {
                "image": "/images/leraningOption/RecordedSession.png",
                "alt": "Recorded Session"
            },
            {
                "image": "/images/leraningOption/1on1Advantage.png",
                "alt": "1 on 1 Advantage"
            }
        ],
        "HomeStatsList": [
            {
                "img": "/images/Stats/Base-feature-icon-1.svg",
                "heading": "55000+",
                "subtitle": "Enrolled Students"
            },
            {
                "img": "/images/Stats/Base-feature-icon-2.svg",
                "heading": "1500+",
                "subtitle": "Worldwide Projects"
            },
            {
                "img": "/images/Stats/Base-feature-icon-6.svg",
                "heading": "60+",
                "subtitle": "Courses"
            },
            {
                "img": "/images/Stats/Base-feature-icon-7.svg",
                "heading": "20+",
                "subtitle": "year Exp"
            }
        ],
        "HomeTeamMembers": [
            {
                "name": "Mr. Lavish Arora",
                "email": "lavisharora133@gmail.com",
                "description": "Under the visionary leadership of Mr. Lavish Arora, RACE has emerged as a cornerstone of technical education in Gurgaon since its inception in 2014. Dedicated to nurturing talent, Mr. Arora drives the institution's commitment to excellence.He believes in empowering students by providing cutting- edge tools and mentorship to help them achieve their full potential in the ever - evolving world of technology.",
                "imageUrl": "/images/teams/OurTrainers.webp",
                // "imageUrl": "/images/teams/lavish.webp",
                "socialMedia": {
                    "facebook": "https://www.facebook.com",
                    "twitter": "https://www.twitter.com"
                }
            },
            {
                "name": "Our Trainers",
                "email": "race@raceitsolution.co.in",
                "description": "At RACE, our trainers are more than educators; they are mentors rooted in the industry, bringing real-world expertise directly to our classrooms. United by a passion to teach and a commitment to shape the future of technology, they collectively ensure that every learning experience is enriching. With a hands-on approach, they equip our students with the knowledge and skills needed to succeed in a competitive landscape.",
                "imageUrl": "/images/teams/OurTrainers.webp",
                "socialMedia": {
                    "facebook": "https://www.facebook.com",
                    "twitter": "https://www.twitter.com"
                }
            }
        ],
        "HomeClients": [
            "Etrains India",
            "Orange",
            "Infosys",
            "Capgemini",
            "TCS",
            "Inflow",
            "Cantilever Labs",
            "Splen Technology",
            "GFG",
            "Leadsoft",
            "EZ Technology",
            "Imparta",
            "GNI University",
            "Sri Venkateshwara College",
            "Rama Chandra College",
            "Bytexl",
            "KL University",
            "Bannari Amman",
            "IIMS",
            "IIMT",
            "Amity University",
            "Manav Rachna",
            "DY Patil College",
            "LPU"
        ],
        "HomeTestimonials": [
            {
                "text": "I can't express how grateful I am for the fantastic learning experience. The mentors go above and beyond to ensure we understand complex concepts. The collaborative environment and the emphasis on real-world applications make this organization stand out.",
                "image": "https://i.pravatar.cc/300?img=1",
                "name": "Aradhana",
                "rate": 5
            },
            {
                "text": "Hello everyone, I am doing job oriented course, practical knowledge as well as additional knowledge also provide , they also provide placement... I suggest to all my Lavish arora sir perfect person for giving the knowledge as well as provide company related scenarios. Once you have to take demo then after you will decide ...",
                "image": "https://i.pravatar.cc/300?img=2",
                "name": "Simron Batra",
                "rate": 4
            },
            {
                "text": "C & C++ awesome trainer Mr.Lavish arora sir.... Too good teaching style & Knowledge but some time don't have much more time we know. Now I got the job in uhg... Nice institute & reasonable fee.",
                "image": "https://i.pravatar.cc/300?img=3",
                "name": "Chandan",
                "rate": 5
            },
            {
                "text": "Coming from a non-technical background, I was a bit apprehensive, but the trainers made the learning process enjoyable and accessible. The support from the staff and the engaging curriculum have made my transition into the tech field smooth and exciting.",
                "image": "https://i.pravatar.cc/300?img=3",
                "name": "Bhupinder Singh",
                "rate": 5
            }
        ],
        "skills_page": [
            "Python",
            "Cloud Computing",
            "AWS Cloud Computing",
            "Google Cloud Computing",
            "Azure Machine Learning",
            "Data Analytics",
            "AI and Machine Learning",
            "Cyber Security",
            "JavaScript (JS)",
            "React JS",
            "Full Stack Web Developer",
            "Java",
            "Adv Java",
            "SQL",
            "Digital Marketing",
            "Networking",
            "Server and Client Architecture",
            "Data Structures",
            "HTML",
            "CSS",
            "React Native",
            "AWS Certification",
            "Microsoft Certification",
            "Azure Certification",
            "Programming",
            "Computer Fundamentals",
            "C Language",
            "C++",
            "Developer",
            "Programmer",
            "Android with Kotlin",
            "Web Development",
            "MongoDB",
            "Angular",
            "Ethical Hacking",
            "Power BI",
            "Tableau",
            "Google Looker",
            "Adv Excel",
            "Office 365",
            "IT",
            "PHP",
            ".NET",
            "jQuery",
            "Ajax",
            "Full Stack Java Developer",
            "AutoCAD",
            "Internship Certification Courses",
            "MBA Coaching",
            "School Computer Subjects",
            "Computer Training Institutes",
            "Management",
            "HR",
            "Android",
            "Basic Computer",
            "BTECH CSE (BE)",
            "BTECH Electronics",
            "BTECH IT",
            "Tally",
            "Marketing",
            "SharePoint",
            "Infosys Preparation",
            "TCS Preparation",
            "Capgemini Preparation",
            "Soft Skills Training",
            "Interview Preparation",
            "Spoken English"
        ],
        "stats_page_forWhom": [
            {
                "name": "Corparate", "description": "Our comprehensive courses cater to a diverse range of industries, ensuring professionals in real estate, finance, banking, manufacturing, telecom, and e-commerce sectors acquire essential skills. Our curriculum is tailored to meet the specific needs of these dynamic industries, providing participants with a competitive edge in their respective fields. Join us to elevate your expertise and excel in the ever-evolving landscape of these key sectors."
            },
            {
                "name": "Students", "description": "Empower your academic journey with our student-focused courses! Designed to ignite curiosity and foster a love for learning, our diverse range of offerings ensures a well-rounded education experience. From engaging content to interactive sessions, we're here to make your learning adventure both exciting and rewarding. Join us in exploring new horizons and unlocking your full potential!"
            },
            {
                "name": "University", "description": "Elevate the academic experience at your university or college with our enriching courses. Tailored to meet the evolving needs of students, our programs combine cutting-edge knowledge with practical skills, preparing learners for success in their chosen fields. Partner with us to provide a dynamic and forward-thinking educational environment, equipping students with the tools they need to excel in their academic pursuits and beyond."
            }
        ],
        "stats_page_courses": [
            {
                "name": "BASIC COMPUTER",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "OPERATING SYSTEM",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "WINDOWS OS",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "LINUX OS",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "WINDOW SERVER OS",
                "duration": "35 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "HARDWARE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ADV HARDWARE TROUBLESHOOT",
                "duration": "45 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ADV COMPUTER FUNDAMENTALS",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SALESFORCE",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MS OFFICE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MS EXCEL",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MS POWER POINT",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MS ACCESS",
                "duration": "15 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MS OUTLOOK",
                "duration": "15 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "INTERNET FUNDAMENTALS",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "COMPUTER HARDAWRE AND SOFTWARE",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "APPLICATION/ SOFTWARE",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MS OFFICE 365",
                "duration": "30 Hours",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SHAREPOINT",
                "duration": "15 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "HTML, HTML FORM, HTML5, HTML MEDIA, HTML GRAPHIC, HTML GEOLOCATION",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CSS, CSS3",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "JAVASCRIPT",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "JQUERY",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AJAX",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "BOOTSTRAP",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "REACT JS",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "REACT NATIVE",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ANGULAR",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SQL DATABASE",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MONGO DB (NO SQL)",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ADV EXCEL",
                "duration": "30 Hours",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MEETING TOOLS LIKE GOOGLE MEET, CISCO, MICROSOFT TEAM, ZOOM ETC",
                "duration": "15 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "FULL STACK WEB DEVELOPMENT",
                "duration": "6 Months",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "FULL STACK MERN DEVELOPMENT",
                "duration": "6 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "FULL STACK JAVA",
                "duration": "6 Months",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "FULL STACK MEAN DEVELOPMENT",
                "duration": "6 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "FULL STACK DATA ANALYTICS",
                "duration": "6 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "POWER BI",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "TABLEAU",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SAS",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "QLICK VIEW",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "GOOGLE LOOKER",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AZURE MACHINE LEARNING",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CLOUD COMPUTING",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AWS CLOUD",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AZURE CLOUD",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "GOOGLE CLOUD",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CYBER SECURITY",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ETHICAL HACKING",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "NETWORKING",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "NETWORKING & HARDWARE",
                "duration": "50 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SERVER AND CLIENT ARCHITECTURE",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "COMPUTER TROUBLESHOOTING",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CCNA",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CCNP",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "PYTHON",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ADV PYTHON",
                "duration": "45 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CORE JAVA",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ADV JAVA",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DOT NET",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "PHP",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CORDOVA & PHONEGAP",
                "duration": "25 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ANDROID",
                "duration": "45 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "IOS",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AI ML",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DATA SCIENCE",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "C LANGUAGE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "C++ LANGUAGE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "MICROSOFT CERTIFICATION (173 + )",
                "duration": "30 Hours Per Certification Training",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AWS CERTIFICATION (13+)",
                "duration": "30 Hours Per Certification Training",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "GOOGLE CERTIFICATION (600 +)",
                "duration": "30 Hours Per Certification Training",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "UX DESIGN",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AUTOCAD",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "3D ANIMATION",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "PHOTOSHOP",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "COREL",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "ILLUSTRATOR",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "INDESIGN",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "BTEC COMPUTER SCIENCE",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "BCA",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "IT",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "MBA",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "BSC",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "MCA",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "MSC",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "MBA IT",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "MBA MARKETING",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "MBA HR",
                "duration": "Per Subject 25 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "DIPLOMA IN MULTIMEDIA",
                "duration": "3 Months",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "DIPLOMA IN FINANCIAL ACCOUNTING",
                "duration": "6 Months",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "SAP",
                "duration": "60 Hours",
                "forWhom": [
                    "Students"
                ]
            },
            {
                "name": "FLASH",
                "duration": "20 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "3D Modelling System Piped in AutoCAD",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "AutoCAD Course – 2D and 3D",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "Complete Course in AutoCAD- 2D and 3D",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "Autodesk CAD/CAM/CAE for Mechanical Engineering Specialization",
                "duration": "70 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DEVELOPER COURSE",
                "duration": "3 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DEVELOPMENT COURSE",
                "duration": "3 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DATABASE COURSE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DIGITAL MARKETING",
                "duration": "40 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "WEB DESIGNING",
                "duration": "3 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "BIG DATA",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "HADOOP",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "TALLY WITH GST",
                "duration": "45 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "Programming Language Courses",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "E-COMMERCE",
                "duration": "90 Hours",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DIGITAL BANKING",
                "duration": "45 Hours",
                "forWhom": [
                    "Corparate",
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SOFTWARE TESTING",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "GRAPHIC DESIGNING",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "CLOUD COMPUTING PROFESSIONAL",
                "duration": "60 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DIPLOMA IN MULTIMEDIA",
                "duration": "3 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DIPLOMA IN FINANCIAL ACCOUNTING",
                "duration": "6 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "DATA ENTRY COURSE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SOFTWARE DEVELOPMENT",
                "duration": "45 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "SPOKEN ENGLISH",
                "duration": "3 Months",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "APTITUDE",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            },
            {
                "name": "REASONING",
                "duration": "30 Hours",
                "forWhom": [
                    "Students",
                    "University"
                ]
            }
        ],
        "teams_othertrainers": [
            {
                "name": "Utkarsh",
                "from": "Haryana, India",
                "designation": "Full Stack Web Developer",
                "image": "/images/teams/others/utkarsh.jpg"
            },
            {
                "name": "Pranay",
                "from": "Madhya Pradesh, India",
                "designation": "Technical Trainer",
                "image": "/images/teams/others/pranay.jpg"
            },
            {
                "name": "Alok Prasher",
                "from": "Uttar Pradesh, India",
                "designation": "Technical Trainer",
                "image": "/images/teams/others/alok.jpg"
            },
            {
                "name": "Kuldeep",
                "from": "Punjab, India",
                "designation": "Developer",
                "image": "/images/teams/others/kuldeep.jpg"
            },
            {
                "name": "Mohit Jain",
                "from": "West Bengal, India",
                "designation": "Soft Skills Trainer",
                "image": "/images/teams/others/mohit.jpg"
            },
            {
                "name": "Sankalp",
                "from": "Uttar Pradesh, India",
                "designation": "Developer / Trainer",
                "image": "/images/teams/others/sankalp.jpg"
            },
            {
                "name": "Rishi",
                "from": "Uttar Pradesh, India",
                "designation": "Software Developer",
                "image": "/images/teams/others/rishi.jpg"
            },
            {
                "name": "Neha",
                "from": "Uttar Pradesh, India",
                "designation": "Operations",
                "image": "/images/teams/others/neha.jpg"
            },
            {
                "name": "Prateek Mishra",
                "from": "Madhya Pradesh, India",
                "designation": "Developer & Trainer",
                "image": "/images/teams/others/prateek.png"
            },
            {
                "name": "Naresh Kumar",
                "from": "Telangana, India",
                "designation": "Technical Trainer",
                "image": "/images/teams/others/naresh.jpg"
            },
            {
                "name": "Aman",
                "from": "Bihar, India",
                "designation": "Developer",
                "image": "/images/teams/others/aman.jpg"
            },
            {
                "name": "Navpreet",
                "from": "Chandigarh, India",
                "designation": "Data Analytics",
                "image": "/images/teams/others/navpreet.jpg"
            },
            {
                "name": "Mr. Ram",
                "from": "Telangana, India",
                "designation": "Trainer",
                "image": "/images/teams/others/ram.jpg"
            },
            {
                "name": "Nazia Khan",
                "from": "Punjab, India",
                "designation": "Marketing Assistant",
                "image": "/images/teams/others/nazia.jpg"
            },
            {
                "name": "Eshika",
                "from": "Punjab, India",
                "designation": "Intership Developer",
                "image": "/images/teams/others/eshika.jpg"
            },
            {
                "name": "SP Tripathi",
                "from": "Bihar, India",
                "designation": "Technical Trainer",
                "image": "/images/teams/others/sp.jpg"
            },
            {
                "name": "Aman",
                "from": "Telangana, India",
                "designation": "Salesforce",
                "image": "/images/teams/others/aman1.jpg"
            },
            {
                "name": "Aditya",
                "from": "Delhi, India",
                "designation": "Technical Trainer",
                "image": "/images/teams/others/aditya.jpg"
            },
            {
                "name": "Raj",
                "from": "Bihar, India",
                "designation": "Technica Trainer",
                "image": "/images/teams/others/raj.jpg"
            },
            {
                "name": "Paras",
                "from": "Haryana, India",
                "designation": "Internship Developer",
                "image": "/images/teams/others/paras.jpg"
            }
        ],
        "teams_headtrainers": [
            {
                "name": "Lavish Arora",
                "from": "Haryana, India",
                "designation": "Corporate Trainer",
                "image": "/images/teams/lavish.webp"
            },
            {
                "name": "Shivani",
                "from": "Delhi, India",
                "designation": "Soft Skills Trainer",
                "image": "/images/teams/shivani.jpg"
            },
            {
                "name": "Suraj",
                "from": "Maharashtra, India",
                "designation": "Technical Trainer",
                "image": "/images/teams/suraj.jpg"
            },
            {
                "name": "Amit Ashokan",
                "from": "Maharashtra, India",
                "designation": "Technical Trainer & Consultant",
                "image": "/images/teams/amit_ashokan.jpg"
            }
        ]
    });

    // useEffect(() => {
        
    //     fetch('/config.json')
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log('Config loaded:', data);
    //             setConfig(data);
    //         })
    //         .catch(error => console.log('Error loading the config file:', error));
    // }, []);



    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
