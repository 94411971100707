import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import ConfigContext from '../../ConfigContext';

function TestimonialCarousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const config = useContext(ConfigContext);
    const testimonials = config.HomeTestimonials ?? [{
        "text": "I can't express how grateful I am for the fantastic learning experience. The mentors go above and beyond to ensure we understand complex concepts. The collaborative environment and the emphasis on real-world applications make this organization stand out.",
        "image": "https://i.pravatar.cc/300?img=1",
        "name": "Aradhana",
        "rate": 5
    }];
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setActiveIndex(current => (current + 1) % testimonials.length);
        }, 3000);  // Change slide every 3 seconds

        return () => clearInterval(intervalRef.current);
    }, [activeIndex]);

    const changeSlide = (index) => {
        clearInterval(intervalRef.current);  // Clear the existing interval
        setActiveIndex(index);
        intervalRef.current = setInterval(() => {
            setActiveIndex(current => (current + 1) % testimonials.length);
        }, 3000);  // Restart the interval
    };

    const nextSlide = () => {
        changeSlide((current) => (current + 1) % testimonials.length);
    };

    const prevSlide = () => {
        changeSlide((current) => (current === 0 ? testimonials.length - 1 : current - 1));
    };

    return (
        <div className="flex flex-col items-center justify-center p-4 mb-4 bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-black">Testimonials</h2>
                    <p className="mb-8 font-light  lg:mb-16 sm:text-xl text-gray-700">What the Students Say About Us?</p>
                </div>
            </div>

            {testimonials.length > 0 && <Fragment>

                <div className="w-full max-h-[32rem]  flex items-center justify-center shadow-xl rounded-3xl">
                    <ChevronDoubleLeftIcon className="h-8 w-8 cursor-pointer" onClick={prevSlide} />

                    <div className="w-full min-h-[34rem] p-4 flex flex-col justify-between items-center">
                        <div className='flex flex-col items-center gap-2 '>
                            <img src={testimonials[activeIndex].image} alt="avatar" className="w-32 h-32 rounded-full" />
                            <p className="text-center text-2xl text-gray-800 font-bold">{testimonials[activeIndex].name}</p>

                            <div className="flex">
                                {Array.from({ length: 5 }, (_, i) => (
                                    <svg key={i} className={`h-6 w-6 ${i < testimonials[activeIndex].rate ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.34 4.130h4.348c.96 0 1.36 1.24.588 1.81l-3.512 2.609 1.321 4.191c.299.95-.755 1.73-1.58 1.18l-3.736-2.47-3.737 2.47c-.825.55-1.879-.23-1.58-1.18l1.322-4.191-3.512-2.609c-.772-.57-.372-1.81.588-1.81h4.349l1.34-4.13z" />
                                    </svg>
                                ))}
                            </div>
                            <p className="text-center text-lg text-gray-900 max-w-md">{testimonials[activeIndex].text}</p>
                        </div>

                        <a href='https://jsdl.in/DT-23AAMMAYA2Y'>
                            <button className="relative inline-flex items-center justify-center p-0.5 mb-6 me-2 overflow-hidden text-base font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 ">
                                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                                    Join the Conversation
                                </span>
                            </button>
                        </a>
                    </div>
                    <ChevronDoubleRightIcon className="h-8 w-8 cursor-pointer" onClick={nextSlide} />
                </div>
            </Fragment>}





        </div>
    );
}

export default TestimonialCarousel;
