import { Fragment, useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Aboutus from './Components/AboutUs.js/Aboutus';
import Contactus from './Components/ContactUs/Contactus';
import Errorpage from './Components/ErrorPage/Errorpage';

import Homepage from './Components/HomePage/Homepage';
import Navbar from './Components/Layouts/Navbar';
import Skills from './Components/Skills/Skills';
import Footer from './Components/Layouts/Footer';
import { Team } from './Components/Teams/Teams';
import Hero from './Components/Hero/Hero';
import ConfigProvider from './ConfigProvider';
import ScrollToHashElement from './Utils/ScrollToHashElement';
function App() {

  return (
    <Fragment>
      <ConfigProvider>
        <Router>
          <Navbar />
          <ScrollToHashElement />
          {/* <Hero /> */}
          <Routes>

            <Route path='/' element={<Homepage />} />
            <Route path='/contact-us' element={<Contactus />} />
            <Route path='/teams' element={<Team />} />
            <Route path='/computer_courses' element={<Skills />} />
            {/* <Route path='/about-us' element={<Aboutus />} /> */}
            <Route path='*' element={<Errorpage />} />
          </Routes>
        </Router>
        <Footer />
      </ConfigProvider>

    </Fragment>
  );
}

export default App;
