import React from "react"
import "./Hero.css"
import { Link } from "react-router-dom"


const Hero = () => {
  return (
    <>
      {/* <section 

        className=" mt-4 text-black min-h-[30rem]">
        <div
         className=" max-w-screen-xl     ">
          <div className=" max-w-3xl text-center flex items-center flex-col align-middle justify-end">
            <h1 className=" bg-clip-text text-3xl font-extrabold  sm:text-5xl" > RACE OF ADVANCE COMPUTING <span className="sm:block"> EDUCATION PVT LTD </span> </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed"> The beautiful thing about learning is that nobody can take it away from you </p>

            <div className="mt-8 flex flex-wrap justify-center gap-4">
              <a
                className="block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                href="#"
              >
                Get Started
              </a>

              <a
                className="block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                href="#"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <section
        // style={{ backgroundImage: "url('/images/hero_image.webp')", backgroundSize: "cover", backgroundPosition: "center" , backgroundRepeat: "no-repeat"}} 
        className=" hero_section  backdrop-opacity-10 	">
        <div className="bg-black bg-opacity-60 grid items-center justify-center  px-4 py-8 min-h-[30vh] md:min-h-[50vh] mx-auto  lg:py-16  break-all">
          <div className="flex flex-col items-center justify-center ">
            <h1 
            // style={{ fontSize: "clamp(5rem, 10vw, 20rem); " }}
              className=" mb-4  font-extrabold  leading-none text-white text-center tracking-widest	text-3xl md:text-7xl ">RACE<span className="text-red-700">IT</span>SOLUTION</h1>
            <p className="  mb-4   text-2xl md:text-4xl  lg:px-28  text-white font-bold text-center">The beautiful thing about learning is that nobody can take it away from you</p>


            <Link to="#contact-us" className="">
              <button type="button" className="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50  rounded-lg text-base leading-8 font-bold px-8 py-2.5 mb-4 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 me-2">
              BOOK YOUR APPOINTMENT
            </button>
            </Link>
          </div>
          {/* <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/images/hero_image.webp" alt="mockup" />
          </div> */}
        </div>
      </section>


    </>
  )
}

export default Hero
