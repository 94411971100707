import React, { Fragment, useContext, useEffect } from 'react'
import Learningoption from './Learningoption'
import ServiceWeOffer from './ServiceWeOffer'
import Hero from '../Hero/Hero'
import Stats from './Stats'

import CourcesWeOffered from './CourcesWeOffered'
import ClientList from './ClientList'
import OurTeamSection from './TeamMember'
import Skills from '../Skills/Skills'
import Footer from '../Layouts/Footer'
import AboutUs from './About'
import TestimonialCarousel from './TestimonialCarousel'
import ConfigContext from '../../ConfigContext'

const Homepage = () => {
  const config = useContext(ConfigContext);
  const home_page_about_list = config.home_page_about_list ?? [];
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <Fragment>
      <Hero />
      <AboutUs  />
      {/* Services We Offer  */}
      {/* <Skills /> */}
      {/* <ServiceWeOffer /> */}
      <Learningoption />
      <Stats />
      <OurTeamSection />
      <ClientList />
      <TestimonialCarousel />
      {/* <CourcesWeOffered /> */}
   
    </Fragment>
  )
}

export default Homepage