import React, { useState } from 'react';



const CoursesComponent = ({ forWhom, courses  }) => {
    const [showCourses, setShowCourses] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryClick = category => {
        setSelectedCategory(category);
    };

    const filteredCourses = selectedCategory
        ? courses.filter(course => course.forWhom.includes(selectedCategory))
        : [];

    return (
        <div className="flex flex-col mx-auto md:p-4">
            <button
                className="mx-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-max"
                onClick={() => setShowCourses(true)}
            >
                Explore Courses


            </button>
            <div className={` items-center justify-center space-x-4 my-7 ${showCourses == false ? "hidden" : "flex"}`}>
                {forWhom.map((category, index) => (
                    <button
                        key={index}
                        className={`${selectedCategory === category.name ? 'bg-blue-500 text-white' : 'text-blue-700 dark:text-blue-500'} hover:text-white border border-blue-700 hover:bg-blue-800  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2 dark:border-blue-500  dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800`}
                        onClick={() => handleCategoryClick(category.name)}
                    >
                        {category.name}

                    </button>
                ))}
            </div>
            <p className="text-lg my-6">
                {selectedCategory
                    ? forWhom.find(category => category.name === selectedCategory).description
                    : ''}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                {filteredCourses.map((course, index) => (
                    <div
                        key={index}
                        className="block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 "
                    >
                        <h5 className="text-lg font-bold">{course.name}</h5>
                        <p className="text-sm">
                            <span>Duration : </span>
                            <span>{course.duration}</span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CoursesComponent;
