import React, { Fragment, useContext, useState } from 'react'
import { Animated } from 'react-animated-css'
import FadeIn from '../utils/FadeIn'
import ConfigContext from '../../ConfigContext'


const SingleLearningOption = ({ image, alt }) => {
    return (
        <div className="w-full md:w-1/5">
            <Animated animationIn="slideInLeft" animationOut="" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <img src={image} alt={alt} />
            </Animated>
        </div>
    )

}

const Learningoption = () => {
    const [isVisible, setIsVisible] = useState(true)
    const config = useContext(ConfigContext);
    const LearningoptionList = config.HomeLearningoptionList ?? [];
    return (
        <Fragment>
            <FadeIn>
                <section className="text-gray-600 body-font">
                    <div className="px-5 py-24 w-full flex flex-wrap">
                        <div className="flex flex-col text-center w-full mb-8">
                            <h1 className="sm:text-3xl text-4xl font-bold title-font  text-gray-900">Learning Options</h1>
                            {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify.</p> */}
                        </div>

                        <div className="flex flex-wrap gap-2 justify-evenly">
                            {/* Tranning */}
                            {LearningoptionList.map((item, index) => (
                                <SingleLearningOption key={index} {...item} />
                            ))}
                           
                            
                           
                        </div>


                    </div>
                </section>
            </FadeIn>
        </Fragment>
    )
}

export default Learningoption