import { useContext, useEffect } from "react";
import ConfigContext from "../../ConfigContext";





const TeamMember = ({ member, ht = 15, rounded = false }) => {
    return (
        <div style={{height:`${ht}rem`}} className={`w-full  ${rounded ? "rounded-3xl" : ""}  relative overflow-hidden`}>
            <img src={member.image} alt={member.name}
                className="transition-transform duration-300 ease-in-out transform hover:scale-125 w-full h-full object-fill" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 text-center">
                <p className="font-bold text-2xl">{member.name}</p>
                <p>{member.from}</p>
                <p className="font-semibold">{member.designation}</p>
            </div>
        </div>
    );
}

// Dummy data for team members


const TeamSection = ({ trainers }) => {
    return (
        <div className="grid gap-5 grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg">
            {trainers.map((member, index) => (
                <TeamMember key={index} member={member} ht={18} />
            ))}
        </div>
    );
}
const MainTeamSection = ({ trainers }) => {
    return (
        <div className="grid gap-10  sm:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg">
            {trainers.map(member => (
                <TeamMember key={member.id} member={member} ht={24} rounded={true} />
            ))}
        </div>
    );
}

export const Team = () => {
    const config = useContext(ConfigContext);
    const othertrainers = config.teams_othertrainers ?? [];
    const headTrainners = config.teams_headtrainers ?? [];

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <section className="flex justify-center items-center">
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
                    <p className="inline-block px-3 py-px mb-4 text-4xl font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                        Discover Our Team
                    </p>
                    <p className="text-base text-gray-700 md:text-lg">
                        Discover the moments, people, and experiences that define our community
                    </p>
                </div>

                <MainTeamSection trainers={headTrainners} />
                <h1 className="text-2xl font-bold text-center my-10  lg:max-w-screen-lg">Meet Our Trainers</h1>
                <TeamSection trainers={othertrainers} />


            </div>
        </section>
    );
};