import React, { useContext } from 'react';
import ConfigContext from '../../ConfigContext';


const AboutUsSingleDetail = ({name, icon, description, gradient}) => {
    return (
        <div className="pt-6 ">
            <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8 lg:min-h-[19rem]">
                <div className="-mt-6">
                    <div className='flex justify-center'>
                        <span className={`inline-flex items-center justify-center p-1  bg-gradient-to-r  ${gradient} rounded-md shadow-lg`}>
                            <img className="h-20 w-20" src={icon} alt={name} />
                        </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{name}</h3>
                    <p className="mt-5 text-base text-gray-600 ">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );

}

const AboutUs = ({  }) => {
    const config = useContext(ConfigContext);
    const about_list = config.home_page_about_list ?? [];

    return (
        <div className="bg-white py-8 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-12">
            <div className="relative max-w-7xl mx-auto">
                <svg
                    className="absolute left-full transform translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Welcome to RACE
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                        Empowering the Next Generation of Tech Innovators
                    </p>
                    <p className="mt-2 text-base text-gray-500">
                        Founded in 2014 by Mr. Lavish Arora, RACE stands at the forefront of technical training in Gurgaon, HR.
                    </p>
                </div>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {about_list.map((item) => {
                            return <AboutUsSingleDetail key={item.name} name={item.name} icon={item.icon} description={item.description} gradient={item.gradient} />
                        
                        })}
                        {/* Section - Programming & Web Development */}
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;