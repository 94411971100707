import React, { useContext, useState } from 'react';
import ConfigContext from '../../ConfigContext';

const ClientList = () => {
    // Define your array of clients
    const config = useContext(ConfigContext);
    const clients = config.HomeClients ?? [];

    
    const [hoverIndex, setIndexHover] = useState(-1);


    // Responsive grid with Tailwind
    const gridClass = "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4";

    // Box styling with Tailwind
    const boxClass = "border border-red-500 rounded p-4 flex justify-center items-center h-24";
    const hoverStyle = {
        background: 'linear-gradient(to right, #FA4B37, #6C3483)',
        color: 'white'
    };


    return (
        <div>
            <h2 className="text-4xl font-bold text-center my-6 mt-10">Client List</h2>
            <p className="text-center mb-8">We have more than 500+ clients</p>
            <div className={gridClass}>
                {clients.map((client, index) => (
                    <div 
                        onMouseEnter={() => {
                            setIndexHover(index);
                        }}
                        onMouseLeave={() => {
                            setIndexHover(-1);
                        }}
                        key={index} className={`${boxClass} hover:text`} style={{
                            
                            ...(hoverIndex==index ? hoverStyle : null)
                        }}>
                        {client}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClientList;