import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../ConfigContext';



const TeamMember = ({ index, name, email, description, imageUrl }) => (
    <div className={`flex  ${index % 2 == 0 ? "flex-col md:flex-row" : "flex-col md:flex-row-reverse"}   gap-4 items-center p-4`}>
        <img className="w-52 h-52 lg:w-72 lg:h-72 rounded-full mb-4 border-2 border-black" src={imageUrl} alt={name} />
        <div>
            <h3 className="text-xl lg:text-2xl font-bold ">{name}</h3>
            <p className="text-sm md:text-base flex flex-row  gap-4 align-middle items-center ">
                <EnvelopeIcon className='w-5 h-5 text-gray-700 ' />
                <span className='font-semibold'><a href={`mailto:${email}`}>{email}</a></span>
            </p>
            <p className="text-base my-4">{description}</p>
        </div>
    </div>
);

const OurTeamSection = () => {
    const config = useContext(ConfigContext);
    const HomeTeamMembers = config.HomeTeamMembers ?? [];

    return <section className="bg-gray-100 py-8">
        <div className="mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-6">Our Team</h2>
            <div className="flex flex-wrap justify-center gap-8">
                {HomeTeamMembers.map((member, index) => (
                    <TeamMember key={member.email} {...member} index={index} />
                ))}
                <Link to={"/teams"}>
                    <button type="button" class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Show All</button>
                </Link>

            </div>
        </div>
    </section>
};

export default OurTeamSection;
