/* This example requires Tailwind CSS v3.0+ */
import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Cources', href: '/computer_courses' },
    { name: 'Teams', href: '/teams' },
    // { name: 'Payment', href: '#payment' },
]


export default function Navbar() {



    const scrollToElement = (elementId) => {
        setTimeout(() => {
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);  // Delay of 100 milliseconds
    };

    useEffect(() => {
        const handleLoad = () => {
            const hash = window.location.hash;
            if (hash) {
                const elementId = hash.substring(1);
                scrollToElement(elementId);
            }
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className=" bg-[rgb(0,51,66)]">
            <div className="px-6 pt-6 lg:px-8">
                <div>
                    <nav className="flex h-16 items-center justify-between" aria-label="Global">
                        <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">RACEITSOLUTION</span>
                                <img className="h-14" src="/images/logo_raceitsolutions.png" alt="" />
                            </Link>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6 text-white " aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
                            {navigation.map((item) => (
                                <Link key={item.name} to={item.href} className="font-semibold text-white hover:text-red-600">
                                    {item.name}
                                </Link>
                            ))}
                            {/* Payment Not Working Donot Know Why  */}
                            <Link to="#payment" className="font-semibold text-white hover:text-red-600">
                               Payment
                            </Link>
                        </div>
                        <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
                            <Link
                                to="#contact-us"
                                className="inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                            >
                                Contact US
                            </Link>
                        </div>
                    </nav>
                    <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
                            <div className="flex h-9 items-center justify-between">
                                <div className="flex">
                                    <Link to="#" className="-m-1.5 p-1.5">
                                        <span className="sr-only">RACE IT SOLUTION</span>
                                        <img
                                            className="h-8"
                                            src="/images/logo_raceitsolutions.png"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="flex">
                                    <button
                                        type="button"
                                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                onClick={()=> setMobileMenuOpen(false)}
                                                className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-black hover:bg-gray-400/10"
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                        {/* For Payments - normal Not Working Donot Know  */}
                                        <a
                                            href="#payment"
                                            // onClick={(()=>{
                                            //     setMobileMenuOpen(false)
                                            //     scrollToElement('payment')
                                            // })}
                                            onClick={() => setMobileMenuOpen(false)}

                                            className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-black hover:bg-gray-400/10"
                                        >
                                            Payment
                                        </a>
                                    </div>
                                    <div className="py-6">
                                        <a
                                            onClick={() => setMobileMenuOpen(false)}
                                            href="#contact-us"
                                            className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-black hover:bg-gray-400/10"
                                        >
                                            Contact US
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </div>
            </div>
            
        </div>
    )
}
